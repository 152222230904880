import React, { useEffect, useState } from 'react'
import { Badge, Dropdown } from 'antd'
import NotificationsList from 'components/stift/widgets/Lists/NotificationsList'
import styles from './style.module.scss'
import backend from '../../../../../services/backend'

function Actions() {
  const [notifications, setNotifications] = useState([])

  const updateNotifications = () =>
    backend.get('/user/notifications').then(response => {
      setNotifications(response.data)
    })
  useEffect(() => {
    updateNotifications()
  }, [])

  const menu = nots => (
    <div className="card cui__utils__shadow width-350 border-0">
      <div className="card-body p-0">
        <NotificationsList notifications={nots} />
      </div>
    </div>
  )
  return (
    <Dropdown
      overlay={menu(notifications)}
      trigger={['click']}
      placement="bottomRight"
      onVisibleChange={updateNotifications}
    >
      <div className={styles.dropdown}>
        <Badge count={notifications.length}>
          <i className={`${styles.icon} fe fe-bell`} />
        </Badge>
      </div>
    </Dropdown>
  )
}

export default Actions
